<template>
  <div class="map_box">
    <div class="container" id="mapContainer" style="width:100%;height:100%;">
    </div>
    <van-button class="btn_map" color="#fff" @click="popupShow" :style="{bottom:show?'40%':'0'}">
      <van-icon :class="{'van-animation':show,'van-end':!show}" name="arrow-up" color="#797877" />
    </van-button>
    <div class="popup_box" v-show="show">
      <div class="popup_top" :style="{background:themecolor}">
        路线图
      </div>
      <div class="popup_center">
        <div v-for="(item,index) in endlist" :key="index">
          <div class="location_box">
            <div class="location_info">
              <div class="location_text" :style="{color:index==clickindex?themecolor:''}">{{item.departure_name}}</div>
              <div class="loction_distance">距离:{{item.distance||"0公里"}} 最短用时:{{item.duration||"0分钟"}}</div>
            </div>
            <div class="loction_retrieval">
              <div class="loction_Route" @click="DrivingShow(index,1)">打车路线</div>
              <div class="loction_Route" @click="TransitShow(index,2)">公交路线</div>
            </div>
          </div>
          <div id="panel" v-show="index==clickindex&&driving&&transitShow">
            <h4 style="font-size: 0.14rem;margin:0.1rem 0;">驾车路线规划</h4>
            <div :id="instruction(index)"></div>
          </div>
          <div id="panel" v-show="index==clickindex&&transit&&transitShow">
            <h4 style="font-size: 0.14rem;margin:0.1rem 0;">公交路线规划</h4>
            <div :id="transitction(index)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import { mapState } from "vuex";
import sideBar from '@/components/sidebar';
import TMap from "@/js/initMap";
export default {
  components: {
    sideBar,
  },
  data() {
    return {
      // 标注点的坐标信息
      pointMarker: { lat: 39.914853, lng: 116.404045 },
      start: { lat: 39.914853, lng: 116.404045 },
      end: { lat: 39.914853, lng: 116.454045 },
      TXMap: '',
      tMap: '',
      marker: '',//点的位置
      labelContent: '',//终点位置信息
      infoWindow: '',//坐标信息
      endlist: [],// 终点列表
      driving: '',//驾车路线
      transit: '',//公交路线
      polylineLayer: '',//路线
      clickiInde: null,
      chicknumb: null,//防止重复点击
      transitShow: true,//路线显示隐藏
      clickindex: null,//点击的是哪一个路线
      routetype: 0,//路线类型
      show: true,
    };
  },
  computed: {
    ...mapState(["themecolors", "themecolor"]),
  },
  created() {
    this.site_id = this.$route.query.site_id;
  },
  mounted() {
    this.$nextTick(() => {
      this.get_front_end_meeting_address()
    })
  },
  methods: {
    instruction(index) {
      return "instruction" + index
    },
    transitction(index) {
      return "transitction" + index
    },
    get_front_end_meeting_address() {
      this.$store.dispatch("get_front_end_meeting_address", {
        site_id: this.site_id,
      }).then(res => {
        if (res.data.code == 200) {
          this.end.lat = res.data.data.destination.y
          this.end.lng = res.data.data.destination.x
          this.pointMarker.lat = res.data.data.destination.y
          this.pointMarker.lng = res.data.data.destination.x
          this.labelContent = res.data.data.destination.destination_name
         
          TMap.init().then((TMap) => {
            this.TXMap = TMap;
            this.tMap = new TMap.Map(document.getElementById("mapContainer"), {
              center: new TMap.LatLng(this.end.lat, this.end.lng), //设置地图中心点坐标
              zoom: 15, //设置地图缩放级别
              viewMode: "2D",
            });
            this.marker = new TMap.MultiMarker({
              map: this.tMap,
              styles: {
                marker: new TMap.MarkerStyle({
                  width: 20, // 样式宽
                  height: 30, // 样式高
                  anchor: { x: 10, y: 30 }, // 描点位置
                }),
              },
              geometries: [
                {
                  // 标记位置(纬度，经度，高度)
                  position: new TMap.LatLng(this.end.lat, this.end.lng),
                  id: 'demo1',
                  styleId: 'marker',
                },
              ],
            })
            this.infowindow = new TMap.InfoWindow({
              content: this.labelContent, //信息窗口内容
              position: new TMap.LatLng(this.end.lat, this.end.lng),//显示信息窗口的坐标
              map: this.tMap,
              offset: { x: 0, y: -30 }
            });
            this.marker.on("click", () => {
              this.infowindow.open()
            })
            let driving = new this.TXMap.service.Driving({
              mp: false,
              policy: 'PICKUP,NAV_POINT_FIRST',
            })
            res.data.data.departure.forEach((element, index) => {
              element.distance = ""
              element.duration = ""
              driving.search({ from: new this.TXMap.LatLng(element.y, element.x), to: new this.TXMap.LatLng(this.end.lat, this.end.lng) }).then((result) => {
                res.data.data.departure[index].distance = (result.result.routes[0].distance / 1000).toFixed(2) + "公里";
                res.data.data.departure[index].duration = result.result.routes[0].duration + "分钟";
              })
            });
            this.endlist = res.data.data.departure
          });
        }
      })
    },
    TransitShow(index, num) {
      if (this.chicknumb != num + '_' + index) {
        this.clickindex = index
        this.chicknumb = num + '_' + index
        this.clickiInde = index
        this.routetype = 2
        this.start.lng = this.endlist[index].x
        this.start.lat = this.endlist[index].y
        this.transitShow = true
        this.driving = ''
        let startPosition = new this.TXMap.LatLng(this.start.lat, this.start.lng)
        let endPosition = new this.TXMap.LatLng(this.end.lat, this.end.lng)
        this.transit = new this.TXMap.service.Transit({ // 新建一个公交路线规划类
          policy: "LEAST_WALKING", // 规划策略
        });
        this.transit.search({ from:new this.TXMap.LatLng(this.start.lat, this.start.lng), to:new this.TXMap.LatLng(this.end.lat, this.end.lng)}).then((result) => { //搜索路径
          var route = result.result.routes[0];
          var instructionContainer = document.getElementById(`transitction${index}`);
          instructionContainer.innerHTML = ''
          var rainbowPaths = [];
          route.steps.forEach((step) => {
            if (step.mode === 'WALKING') {
              rainbowPaths.push({ path: step.polyline, color: 'rgba(51, 51, 255, 1)', }); //绘制步行路线
              instructionContainer.innerHTML += `<h5 style='margin:0.05rem 0'>步行（${step.duration}分钟）</h5>`;
              step.steps && step.steps.forEach((subStep, index) => {
                instructionContainer.innerHTML += `<p style='margin:0.05rem 0'>${index + 1}. ${subStep.instruction}</p>` // 显示步行指引
              });
            }
            else {
              step.lines.forEach((line) => {
                if (line.vehicle === 'SUBWAY') {
                  rainbowPaths.push({ path: line.polyline, color: 'rgba(245, 185, 23, 1)' }); //绘制地铁路线
                } else {
                  rainbowPaths.push({ path: line.polyline, color: 'rgba(28, 204, 108, 1)' }); //绘制非地铁公交路线
                }
                instructionContainer.innerHTML += `<h5 style='margin:0.05rem 0'>搭乘${line.title}（${line.duration}分钟）</h5>`; // 显示搭乘指引
                instructionContainer.innerHTML += `<p style='margin:0.05rem 0'>上车站：${line.geton.title}</p>`;
                instructionContainer.innerHTML += `<p style='margin:0.05rem 0'>下车站：${line.getoff.title}</p>`;
              });
            }
          });
          let obj = {
            id: 'transit_1',
            styleId: 'style_blue',
            rainbowPaths: rainbowPaths,
          }
          if (this.polylineLayer) {
            this.polylineLayer.remove("transit_1")
            this.polylineLayer.remove("pl_1")
            this.polylineLayer.add(obj)
            this.marker.updateGeometries([
              {
                id: 'start',
                styleId: 'start',
                position: startPosition,
              },
              {
                id: 'end',
                styleId: 'end',
                position: endPosition,
              },
            ],)
            return
          }
          this.polylineLayer_data(obj, startPosition, endPosition)
        }).catch(r=>{
          var instructionContainer = document.getElementById(`transitction${index}`);
          instructionContainer.innerHTML = ''
          instructionContainer.innerHTML += `<p style='margin:0.05rem 0;'>暂无公交路线</p>`
        });
      } else {
        this.transitShow = !this.transitShow
      }
    },
    DrivingShow(index, num) {
      if (this.chicknumb != num + '_' + index) {
        this.clickindex = index
        this.chicknumb = num + '_' + index
        this.clickiInde = index
        this.routetype = 1
        this.start.lng = this.endlist[index].x
        this.start.lat = this.endlist[index].y
        this.transitShow = true
        this.transit = ''
        let startPosition = new this.TXMap.LatLng(this.start.lat, this.start.lng)
        let endPosition = new this.TXMap.LatLng(this.end.lat, this.end.lng)
       
        this.driving = new this.TXMap.service.Driving({
          mp: false,
          policy: 'PICKUP,NAV_POINT_FIRST',
        })
        this.driving.search({ from: startPosition, to: endPosition }).then((result) => {
          document.getElementById(`instruction${index}`).innerHTML = ''
          result.result.routes[0].steps.forEach((step, num) => {
            document.getElementById(`instruction${index}`).innerHTML += `<p style='margin:0.05rem 0'>${num + 1}. ${step.instruction}</p>`;
          })
          let obj = {
            id: 'pl_1',
            styleId: 'style_blue',
            paths: result.result.routes[0].polyline,
          }
          if (this.polylineLayer) {
            this.polylineLayer.remove("transit_1")
            this.polylineLayer.remove("pl_1")
            this.polylineLayer.add(obj)
            this.marker.updateGeometries([
              {
                id: 'start',
                styleId: 'start',
                position: startPosition,
              },
              {
                id: 'end',
                styleId: 'end',
                position: endPosition,
              },
            ],)
            return
          }
          this.polylineLayer_data(obj, startPosition, endPosition)
        })

      } else {
        this.transitShow = !this.transitShow
      }
    },
    polylineLayer_data(data, startPosition, endPosition) {
      this.polylineLayer = new this.TXMap.MultiPolyline({
        id: 'polyline-layer',
        map: this.tMap,
        styles: {
          style_blue: new this.TXMap.PolylineStyle({
            color: '#3777FF',
            width: 8,
            borderWidth: 5,
            borderColor: '#FFF',
            lineCap: 'round',
          }),
        },
        geometries: [data],
      });
      this.marker = new this.TXMap.MultiMarker({
        // 创造MultiMarker显示起终点标记
        id: 'marker-layer',
        map: this.tMap,
        styles: {
          start: new this.TXMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/start.png',
          }),
          end: new this.TXMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/end.png',
          }),
        },
        geometries: [
          {
            id: 'start',
            styleId: 'start',
            position: startPosition,
          },
          {
            id: 'end',
            styleId: 'end',
            position: endPosition,
          },
        ],
      });
    },
    popupShow() {
      this.show = !this.show
    },
  },
};
</script>
<style scoped lang="scss">
.map_box {
  position: relative;
  height: 100%;
  font-size: 12px;

  .btn_map {
    z-index: 99999;
  }

  .popup_box {
    position: absolute;
    bottom: 0;
    min-height: 40%;
    z-index: 99999;
    width: 100%;
    background: #fff;
    max-height: 40%;
    overflow-y: auto;
  }

  .popup_top {
    width: 100%;
    height: .5rem;
    line-height: .5rem;
    font-size: .16rem;
    color: #fff;
    text-align: center;

  }

  .popup_center {
    height: calc(100% - 0.5rem);
    overflow-y: auto;
    padding: 0 0.1rem;

    .location_box {
      padding: .1rem 0;
      text-align: left;
      border-bottom: 1px solid #999;
      display: flex;
      align-items: center;

      .location_icon {
        font-size: .28rem;
        margin-right: .1rem;
      }

      .location_info {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: .1rem;

        .location_text {
          font-size: .14rem;
          margin-bottom: .1rem;
          display: -webkit-box;
          overflow: hidden;
          word-break: break-all;
          width: 100%;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .loction_distance {
          font-size: .12rem;
        }
      }
    }

    .loction_retrieval {
      .loction_Route {
        padding: 0.05rem .1rem;
        font-size: .12rem;
        color: #63605e;
        border: solid 0.01rem #909090;
        border-radius: .3rem;

        &:first-child {
          margin-bottom: 0.1rem;
        }
      }
    }

  }

  .van-button {
    width: 1rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .van-icon {
      font-size: .24rem;
      font-weight: bold;
    }
  }

  .van-animation {
    animation: mymove 0.3s 1 forwards;
  }

  .van-end {
    animation: end 0.3s 1 forwards;
  }

  @keyframes mymove {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes end {
    0% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }
}
</style>
<style lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: .07rem;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .addr {
      font-size: .12rem;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>